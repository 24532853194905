<template>
    <div>
        <DatePicker v-model="date" :disabled-date="disabledAfterToday" :lang="lang" />
    </div>
</template>

<script>
import moment from "moment";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import 'vue2-datepicker/locale/es';

export default {
    name: "date-picker",
    components: {
        DatePicker,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        disabledBeforeToday: {
            type: Boolean,
            default: false,
            require: false
        },
        initDate: {
            type: String,
            default: "",
            require: false
        },
    },
    data() {
        return {
            date: this.value ? moment(this.value).toDate() : null,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
            },
        };
    },
    watch: {
        value(value) {
            let date = moment(value).toDate();
            this.date = moment(date).isValid() ? date : "";
        },
        date(date) {
            let value = moment(date).format("YYYY-MM-DD");
            value = moment(value).isValid() ? value : "";
            this.$emit("input", value);
        },
    },
    methods: {
        disabledAfterToday(date) {
            var today = new Date();

            if (this.initDate !== "") {
                today = new Date(this.initDate);
                today.setDate(today.getDate() + 1);
            }

            today.setHours(0, 0, 0, 0);

            if (this.disabledBeforeToday === true) {
                return date < today;
            }

            return date > today
        },
    }
};
</script>

<style>
.mx-datepicker {
    width: 100%;
}
</style>